var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "px-0", attrs: { fluid: "" } },
    [
      _c("h3", { staticStyle: { margin: "0" } }, [
        _vm._v(_vm._s(this.FormMSG(10, "Budget tracking"))),
      ]),
      _c("budget-dashboard-department-selector", {
        attrs: { departments: _vm.departments },
        on: { changeSelectedDepartment: _vm.setSelectedDepartmentValue },
      }),
      _c("budget-dashboard-chart", {
        attrs: { "graph-data": _vm.budgetDataPerDimension },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }