var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "graphs-container" },
    _vm._l(_vm.filteredGraphData, function (dataPoint) {
      return _c(
        "div",
        { key: dataPoint.key, staticClass: "graphs-graph" },
        [
          _c("h5", [_vm._v(_vm._s(dataPoint.dimension.message))]),
          _c("single-bar-chart", {
            attrs: {
              "amount-total": dataPoint.amountTotal,
              "amount-left": dataPoint.amountLeft,
              "department-name": dataPoint.dimension.message,
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }