<script>
import { HorizontalBar } from 'vue-chartjs';
import { store } from '@/store';
import numeral from 'numeral';
import languageMessages from '@/mixins/languageMessages';

//const currencySymbol = store.state.myProfile.currencySymbol;
const currencySymbol = (store && store.state && store.state.myProfile && store.state.myProfile.currencySymbol) || '€';
export default {
	name: 'SingleBarChart',
	mixins: [languageMessages],
	extends: HorizontalBar,
	props: {
		amountTotal: {
			type: Number
		},
		amountLeft: {
			type: Number
		},
		departmentName: {
			type: String
		}
	},
	data() {
		return {};
	},
	computed: {
		chartdata() {
			var dt = this.buildData(this.amountTotal, this.amountLeft);

			//console.log("chartdata:",dt);
			dt.datasets[0].label = this.FormMSG(11, 'Total amount');
			dt.datasets[1].label = this.FormMSG(10, 'Budget'); //
			return dt;
		}
	},
	watch: {
		chartdata() {
			this.renderChart(this.chartdata, this.getChartOptions());
		}
	},
	mounted() {
		this.renderChart(this.chartdata, this.getChartOptions());
	},
	methods: {
		buildData(amountTotal, amountLeft) {
			return {
				datasets: [
					{
						data: [amountTotal],
						label: 'Total amount',
						backgroundColor: 'red',
						barPercentage: 0.5,
						categoryPercentage: 1.0
					},
					{
						data: [amountLeft + amountTotal],
						label: 'Budget',
						backgroundColor: 'green',
						barPercentage: 0.5,
						categoryPercentage: 1.0
					}
				]
			};
		},
		updateChart() {
			this.renderChart(this.chartdata, this.getChartOptions());
		},
		getChartOptions() {
			return {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
								display: true
							},
							gridLines: {
								display: false
							}
						}
					],
					xAxes: [
						{
							ticks: {
								beginAtZero: true,
								callback: function (value) {
									return `${currencySymbol} ${numeral(value).format('0.0a')}`;
								}
							},
							gridLines: {
								display: true
							}
						}
					]
				},
				responsive: true,
				maintainAspectRatio: false,
				tooltips: {
					callbacks: {
						title: () => {
							return this.departmentName;
						},
						label: function ({ xLabel }, data) {
							return `${currencySymbol} ${numeral(xLabel).format('0.0a')}`;
						}
					}
				}
			};
		}
	}
};
</script>

<style scoped>
#horizontalbar-chart {
	height: 120px !important;
}
</style>
