<template>
	<b-row class="selector-container">
		<b-col xl="6">
			<b-form-select v-model="selected" :options="options" size="sm" class="mt-3" @change="selectDepartment" />
		</b-col>
	</b-row>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'budgetDashboardDepartmentSelector',
	mixins: [languageMessages],
	props: {
		departments: {
			type: Array,
			default: []
		}
	},
	data() {
		return {
			selected: '__ALL__'
		};
	},
	computed: {
		options() {
			var str = this.FormMSG(10, 'All departments');
			return [].concat(
				[
					{
						value: '__ALL__',
						text: str // "All departments",
					}
				],
				this.departments.map((dep) => ({
					value: dep.value,
					text: dep.message
				}))
			);
		}
	},
	methods: {
		selectDepartment(departmentValue) {
			this.$emit('changeSelectedDepartment', departmentValue);
		}
	}
};
</script>

<style scoped>
.selector-container {
	margin-bottom: 24px;
}
</style>
