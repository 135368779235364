var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "selector-container" },
    [
      _c(
        "b-col",
        { attrs: { xl: "6" } },
        [
          _c("b-form-select", {
            staticClass: "mt-3",
            attrs: { options: _vm.options, size: "sm" },
            on: { change: _vm.selectDepartment },
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }