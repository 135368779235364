<template>
	<div class="graphs-container">
		<div v-for="dataPoint in filteredGraphData" :key="dataPoint.key" class="graphs-graph">
			<h5>{{ dataPoint.dimension.message }}</h5>
			<single-bar-chart :amount-total="dataPoint.amountTotal" :amount-left="dataPoint.amountLeft" :department-name="dataPoint.dimension.message" />
		</div>
	</div>
</template>
<script>
import SingleBarChart from './SingleBarChart';

export default {
	name: 'BudgetDashboardChart',
	components: {
		SingleBarChart
	},
	props: {
		graphData: {
			type: Array,
			default: []
		}
	},
	computed: {
		filteredGraphData() {
			return this.graphData.filter(({ amountTotal, amountLeft }) => {
				if (!amountTotal && !amountLeft) {
					return false;
				}
				if (isNaN(amountTotal) || isNaN(amountLeft)) {
					return false;
				}
				return true;
			});
		}
	}
};
</script>

<style scoped>
.graphs-container {
	display: flex;
	width: 100%;
	align-items: flex-start;
	flex-flow: row wrap;
	justify-content: space-between;
}

.graphs-graph {
	width: 350px;
	padding-bottom: 24px;
	padding-right: 24px;
}
</style>
